<script setup>
import { computed, onMounted, onUnmounted, ref, useTemplateRef } from 'vue';
import ProjectItem from '@/components/common/ProjectItem';
import SectionHeader from '@/components/home/SectionHeader';

const props = defineProps({
  displayNumber: { type: Number, required: true },
  label: { type: String, required: true },
  loading: { type: Boolean, default: false },
  href: { type: String },
  projects: { type: Array, required: true },
  rows: { type: Number, default: 4 },
  title: { type: String },
});

const containerWidth = ref(0);
const container = useTemplateRef('container');

// コンテナの幅を監視
const updateContainerWidth = () => {
  if (container.value) {
    containerWidth.value = container.value.offsetWidth;
  }
};

onMounted(() => {
  updateContainerWidth(); // 初回取得
  const observer = new ResizeObserver(updateContainerWidth);
  if (container.value) {
    observer.observe(container.value);
  }
  onUnmounted(() => observer.disconnect());
});

// 列数を計算（最小2列、最大10列）
const columns = computed(() =>
  Math.max(2, Math.min(10, Math.floor(containerWidth.value / 320))),
);

// 表示すべきプロジェクトリスト（rows * columns で制限）
const visibleProjects = computed(() =>
  props.projects.slice(0, props.rows * columns.value),
);
</script>

<template>
  <section
    v-if="loading || visibleProjects?.length"
    ref="container"
    :class="`${label}-section project-list-wrapper`"
  >
    <SectionHeader :href :label :title>
      <slot v-if="$slots[`title.append`]" name="title.append" />
    </SectionHeader>
    <ul
      v-if="visibleProjects?.length || loading"
      class="project-list grid gap-0 -m-4 sm:-m-5 sm:px-2 trimmed"
    >
      <slot name="projects" :projects>
        <li
          v-for="(project, key) in visibleProjects"
          :key
          class="my-4 mx-1 sm:my-5 sm:mx-3"
        >
          <slot name="project" :project>
            <ProjectItem :index="key" no-avatar :project :tracking-id="label">
              <template #[`thumbnail.append`]>
                <slot name="project.thumbnail.append" :project />
              </template>
              <template #subtext>
                <slot name="project.subtext" :project />
              </template>
              <template #status>
                <slot name="project.status" :project />
              </template>
            </ProjectItem>
          </slot>
        </li>
      </slot>
      <template v-if="loading">
        <slot name="loaders" :amount="displayNumber">
          <li
            v-for="key in displayNumber"
            :key
            class="my-4 mx-1 sm:my-5 sm:mx-3"
          >
            <slot name="loading" :order="key">
              <ProjectItem loading no-avatar>
                <template #[`loader.subtext`]>
                  <slot name="project.loader.subtext" />
                </template>
                <template #[`loader.status`]>
                  <slot name="project.loader.status" />
                </template>
              </ProjectItem>
            </slot>
          </li>
        </slot>
      </template>
    </ul>
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.project-list-wrapper {
  container-type: inline-size;
}

.project-list {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.project-list.trimmed {
  grid-auto-rows: 0;
  grid-template-rows: repeat(v-bind(rows), auto);
  overflow: hidden;
}

@for $i from 2 through 10 {
  @container (min-width: #{320 * $i}px) {
    .project-list {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }
}
</style>
