// @ts-check
import client from '@/modules/api/createClient';

/**
 * @typedef {{
 * page?: number;
 * per_page?: number;
 * tag_ids?: string;
 * type: 'maybe-missing';
 * }} FetchProjectsEndRequest
 */

/**
 * @param {{
 * page?: number,
 * perPage?: number,
 * tagIds?: number[],
 * }} [opts={}] オプショナルなパラメータ。
 * `tagIds` を指定すると、そのタグを持つプロジェクトも結果に考慮されるようになる。
 */
const fetchProjectsEnd = ({ page, perPage = 8, tagIds = [] } = {}) => {
  /** @type {FetchProjectsEndRequest} */
  const params = { page, per_page: perPage, type: 'maybe-missing' };
  if (tagIds.length > 0) {
    params.tag_ids = tagIds.join(','); // カンマ区切りの文字列へ直してリクエスト
  }

  return client.phpApiClient.get('/projects', { params });
};

export default {
  fetchProjectsEnd,
};
