<script setup>
import { computed, onMounted, ref } from 'vue';
import useDevice from '@/composables/common/device';
import useRanking from '@/composables/ranking/ranking';
import NewArrivalSection from '@/components/project/explore/NewArrivalSection';
import NewArrivalListSection from '@/components/project/explore/NewArrivalListSection';
import RankingSection from '@/components/project/explore/RankingSection';
import RecommendSection from '@/components/project/explore/RecommendSection';
import SimilarSection from '@/components/project/explore/SimilarSection';

const props = defineProps({
  projectId: { type: Number, required: true },
  tagIds: { type: String, default: '' },
});

const { pc } = useDevice();
const { getMostFrequentTopTagId, getRankingCategoryFromTagId } = useRanking();

const rankingCategory = ref();
const tagIds = computed(() => {
  if (!props.tagIds) return [];

  return props.tagIds
    .split(',')
    .map(Number)
    .filter(id => id !== 0); // PHPから0が渡される可能性があるため
});

onMounted(async () => {
  if (tagIds.value.length > 0) {
    const frequentTagId = await getMostFrequentTopTagId(tagIds.value);
    rankingCategory.value = await getRankingCategoryFromTagId(frequentTagId);
  }
});
</script>

<template>
  <div class="flex flex-col gap-12 px-6">
    <template v-if="pc">
      <SimilarSection class="hidden" :project-id :tag-ids />
      <RecommendSection :project-id />
    </template>
    <RankingSection />
    <RankingSection v-model="rankingCategory" class="hidden px-2709-b" />
    <!-- PX-2796でBが勝利した前提で実装 -->
    <NewArrivalSection :project-id :tag-ids />
    <NewArrivalListSection class="hidden px-2908-b" :project-id :tag-ids />
  </div>
</template>
