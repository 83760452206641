/** @deprecated Use `SORT_POPULAR` instead. */
export const SortKeyCollectedMoney = 'collected_money';
export const PER_PAGE = 15;
export const PICKUP_DISPLAY_NUMBER = 18;
export const RANKING_DISPLAY_NUMBER = 30;
export const HISTORY_DISPLAY_NUMBER = 20;
export const FILTER_ONGOING = 'ongoing';
export const FILTER_IN_STORE = 'in_store';
/**
 * @typedef {Object} SortOption
 * @property {string} key
 * @property {string} name
 * @property {boolean} value
 */
/**
 * @type {ReadonlyArray<SortOption>}
 */
export const FILTER_OPTIONS = Object.freeze([
  { key: FILTER_ONGOING, name: '実施中', value: true },
  { key: FILTER_IN_STORE, name: 'STORE販売中', value: true },
]);
export const NO_FILTER_OPTION = Object.freeze([]);
export const KIND_ONGOING = 'funded,donative';
export const KIND_IN_STORE = 'store';
export const SORT_POPULAR = 'collected_money';
export const SORT_SUBSCRIPTION = 'subscription_count';
export const SORT_OPTION_KEY_NEW = 'new';
export const SORT_OPTION_KEY_POPULAR = 'popular';
/**
 * @typedef {Object} SortOption
 * @property {string} key
 * @property {string} label
 */
/**
 * @type {ReadonlyArray<SortOption>}
 */
export const SORT_OPTIONS = Object.freeze([
  { key: SORT_OPTION_KEY_NEW, label: '新着順' },
  { key: SORT_OPTION_KEY_POPULAR, label: '人気順' },
]);
/** @type {ReadonlyArray<SortOption>} */
export const NO_SORT_OPTION = Object.freeze([]);
