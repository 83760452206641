<script setup>
import SectionBase from '@/components/project/explore/SectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { FILTER_ONGOING } from '@/consts/discover';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

const props = defineProps({
  projectId: { type: Number },
  tagIds: { type: Array, default: () => [] },
});

const { fetchNewProjects, fetchProjectsByTagIds, filter, loading, projects } =
  useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() => {
  if (props.tagIds.length > 0) {
    filter.value = [FILTER_ONGOING];
    fetchProjectsByTagIds(props.tagIds, {
      excludedProjectId: props.projectId,
      perPage: PROJECT_DISPLAY_NUMBER * 5,
      random: PROJECT_DISPLAY_NUMBER,
    });
    return;
  }

  fetchNewProjects({
    perPage: PROJECT_DISPLAY_NUMBER * 5,
    random: PROJECT_DISPLAY_NUMBER,
  });
});
</script>

<template>
  <SectionBase
    href="/discover/new"
    label="new"
    :loading
    :projects
    title="新着のプロジェクト"
  />
</template>
