import { ref } from 'vue';

const snackbar = ref(false);
const props = ref({});
const message = ref('');
const action = ref(() => {});

export default function useSnackbar() {
  const defaultOptions = {
    action: () => {},
    actionLabel: '',
    message: '',
  };

  const showSnackbar = (color, options = {}) => {
    snackbar.value = true;
    props.value.color = color;

    const mergedOptions = { ...defaultOptions, ...options };

    Object.keys(mergedOptions).forEach(key => {
      if (key === 'message') message.value = mergedOptions[key];
      else if (key === 'action') action.value = mergedOptions[key];
      else props.value[key] = mergedOptions[key];
    });
  };

  const show = (options = {}) => {
    showSnackbar('primary', options);
  };

  const showError = (options = {}) => {
    showSnackbar('danger', options);
  };

  return {
    action,
    message,
    props,
    snackbar,
    show,
    showError,
  };
}
