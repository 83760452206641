<script setup>
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectListBase from '@/components/project/explore/ProjectListBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import {
  NEW_ARRIVAL_DISPLAY_NUMBER,
  NEW_ARRIVAL_ROWS,
  NEW_ARRIVAL_ROWS_MD,
} from '@/consts/project/explore/count';
import { computed } from 'vue';

const props = defineProps({
  projectId: { type: Number },
  tagIds: { type: Array, default: () => [] },
});

const { smAndDown } = useDisplay();
const displayNumber = NEW_ARRIVAL_DISPLAY_NUMBER;

const rows = computed(() =>
  smAndDown.value ? NEW_ARRIVAL_ROWS : NEW_ARRIVAL_ROWS_MD,
);

const { fetchNewProjects, fetchProjectsByTagIds, filter, loading, projects } =
  useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() => {
  if (props.tagIds.length > 0) {
    filter.isOngoing = true;
    fetchProjectsByTagIds(props.tagIds, {
      excludedProjectId: props.projectId,
      perPage: displayNumber * 2,
      random: displayNumber,
    });
    return;
  }

  // タグがない場合
  fetchNewProjects({
    perPage: displayNumber * 2,
    random: displayNumber,
  });
});
</script>

<template>
  <ProjectListBase
    :display-number
    label="new"
    :loading
    href="/discover/new"
    :projects
    :rows
    title="新着のプロジェクト"
  />
</template>
