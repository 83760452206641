<script setup>
import { computed } from 'vue';

const props = defineProps({
  height: {
    type: [String, Number],
    default: 4,
  },
  percent: {
    type: Number,
    default: 0,
  },
});

const height = computed(() => `${Number(props.height) / 16}rem`);

const backgroundSize = computed(
  () =>
    `${props.percent > 0 ? Math.max(100, (100 / props.percent) * 100) : 100}%`,
);
const width = computed(() => `${Math.min(100, props.percent)}%`);
</script>

<template>
  <div class="success-bar flex grow rounded-full bg-secondary overflow-hidden">
    <div v-if="percent"></div>
  </div>
</template>

<style scoped>
@keyframes grow {
  0% {
    background-size: 250%;
    opacity: 0.5;
    width: 0;
  }

  100% {
    background-size: v-bind(backgroundSize);
    opacity: 1;
    width: v-bind(width);
  }
}

.success-bar {
  height: v-bind(height);
}

.success-bar > div {
  animation: grow 0.6s cubic-bezier(0.4, 0.12, 0.4, 0.92) 0.3s forwards;
  background: linear-gradient(
    90deg,
    #1ad4ff 0%,
    #1ad0ff 17%,
    #9ea8ff 28%,
    #dd79ff 41%,
    #ff5998 53%,
    #ff6a42 59%,
    #ff9811 67%,
    #ffe600 75%,
    #f1f504 84%,
    #b5ed0c 100%
  );
  will-change: background-size, opacity, width;
}
</style>
